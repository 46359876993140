import React from 'react';
import ReactDOM from 'react-dom/client';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { createBrowserRouter, RouterProvider, Outlet, useLocation } from 'react-router-dom';
import { YMInitializer } from '@appigram/react-yandex-metrika';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
  HomePage,
  NotFoundPage,
  EventsPage,
  AboutPage,
  EventPage,
  ErrorPage,
  FreeDayPage,
  NewYearPage,
  BookingPage
} from './pages';

import { Header } from './components';

import { en, ru } from './translations'

import './index.css';

i18n
  .use(initReactI18next)
  .init({
    resources: { en, ru },
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false
    }
  });

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID
}

TagManager.initialize(tagManagerArgs);

const Layout = (props: any) => {
  const location = useLocation();
  const r = [/promo/, /new-year/];

  return (
    <>
      {!r.find(pagePath => {
        return pagePath.test(location.pathname)
      }) ? <Header /> : null}
      <Outlet />
    </>
  )
};

const router = createBrowserRouter([
  {
    element: <Layout/>,
    children: [
      {
        path: '/',
        element: <HomePage/>
      },
      {
        path: '/about',
        element: <AboutPage/>
      },
      {
        path: '/events',
        element: <EventsPage/>
      },
      {
        path: '/events/:eventId',
        element: <EventPage/>
      },
      {
        path: '/promo/free-day',
        element: <FreeDayPage/>
      },
      {
        path: '/error',
        element: <ErrorPage/>
      },
      {
        path: '/new-year-2025',
        element: <NewYearPage/>
      },
      {
        path: '/booking',
        element: <BookingPage/>
      },
      {
        path: '*',
        element: <NotFoundPage/>,
      },
    ]
  }
]);

const App = () => (
  <RouterProvider router={router}/>
)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <>
      <App/>
      <ToastContainer />
      <YMInitializer accounts={[process.env.REACT_APP_YA_METRIKA]} options={{webvisor: true}}/> 
  </>
);
