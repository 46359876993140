import { Link } from '@material-ui/core';
import { toast } from 'react-toastify';

export const CustomToastSubmit = () => (
  <div><p style={{marginBottom: '8px'}}>Вы успешно прошли регистрацию!</p> <p style={{marginBottom: '8px'}}>Мы можем с вами связаться через мессенджер, который вы указали.</p> <p>Для отмены регистрации - напишите нам в <Link href="https://t.me/lan_yerevan" target="_blank">telegram</Link> ✅</p></div>
);

export const submitNotify = () => toast.success(CustomToastSubmit, {
  position: 'bottom-right',
  autoClose: 15000,
  theme: 'dark'
});

export const CustomToastWithLink = () => (
  <div><p style={{marginBottom: '8px'}}>К сожалению, у нас закончились места на это мероприятие.</p> <p>Свяжитесь с нами в <Link href="https://t.me/lan_yerevan" target="_blank">telegram</Link> </p></div>
);

export const soldOutNotify = () => toast.error(CustomToastWithLink, {
  position: 'bottom-right',
  autoClose: 10000,
  theme: 'dark'
});

export const CustomToastError = () => (
  <div>Что-то пошло не так. Свяжитесь с нами в <Link href="https://t.me/lan_yerevan" target="_blank">telegram</Link> </div>
);

export const errorNotify = () => toast.error(CustomToastError, {
  position: 'bottom-right',
  autoClose: 10000,
  theme: 'dark'
});

export const CustomBookingSubmit = () => (
  <div><p style={{marginBottom: '8px'}}>Вы успешно прошли регистрацию!</p></div>
);

export const submitBookingNotify = () => toast.success(CustomBookingSubmit, {
  position: 'bottom-right',
  autoClose: 15000,
  theme: 'dark'
});